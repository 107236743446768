<template>
	<Modal
		id="modal-first-access"
		confirmLabel="Não mostrar novamente"
		size="lg"
		:gap="1"
		@confirm="submit"
		@hidden="close"
	>
		<p
			v-if="config.content"
			v-html="config.content"
			class="content"
		/>
		<iframe
			v-if="config.embed_link"
			:src="config.embed_link"
			class="frame"
		/>
	</Modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
	props: [],
	methods: {
		...mapActions({
			actionShowModalFirstAccess: "home/actionShowModalFirstAccess",
			actionSaveSiteMetas: "user/actionSaveSiteMetas",
			actionGetMetaSiteScoped: "user/actionGetMetaSiteScoped",
		}),
		close() {
			this.$bvModal.hide("modal-first-access");
			this.actionShowModalFirstAccess({
				active: false,
			});
			if (this.shouldOptoutOnClose) {
				this.optout();
			}
		},
		optout() {
			return this.actionSaveSiteMetas({
				key: "first_access_optout",
				value: "confirmed",
			});
		},
		submit() {
			this.optout();
			this.close();
		},
		async visible() {
			const shouldShow = await this.actionShowModalFirstAccess();
			if (shouldShow) {
				this.$bvModal.show("modal-first-access");
			}
		},
	},
	mounted() {
		this.visible();
	},
	computed: {
		...mapGetters({
			getModalFirstAccess: "home/getModalFirstAccess",
			getSettings: "config/getSettings",
			getUserMetas: "user/getMetas",
		}),
		metaId() {
			return this.actionGetMetaSiteScoped("first_access_optout");
		},
		config() {
			return JSON.parse(this.getSettings?.["first_access_config"] || "{}");
		},
		shouldOptoutOnClose() {
			return this.config["display"] == "onetime";
		},
	},
};
</script>

<style lang="scss">
.h-full {
	.content {
		p,
		span {
			color: var(--fontcolor) !important;
		}
	}
}
</style>

<style lang="scss" scoped>
.content {
	color: var(--fontcolor) !important;
}

.frame {
	height: 27rem;
	margin: 1rem 0;
	width: 100%;
	border: none;
}

.content {
	overflow-wrap: break-word;
	overflow: auto;
	max-height: 70vh;
	margin: 1rem 0;
}
</style>
